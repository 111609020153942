<template>
    <div class="l-padded">
        <AxButton :disabled="isLoading" :loading="isLoading" @click="calculate">
            {{ $t('calculate') }}
        </AxButton>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { httpHelper } from '@/utils'
import AxButton from '../AxButton'

export default {
    name: 'NavigationView',
    components: {
        AxButton,
    },
    data() {
        return {
            isLoading: false,
            isLocating: false,
        }
    },
    computed: {
        ...mapState('map', ['mapInstance', 'lastUserPosition']),
        ...mapGetters('tracker', ['fullBinAssets']),
    },
    watch: {
        lastUserPosition(value) {
            if (this.isLocating && value) {
                this.calculate()
            }
        },
    },
    methods: {
        ...mapMutations('map', [
            'setPositionTrackingEnabled',
            'setNavigationRoute',
        ]),
        async calculate() {
            this.isLoading = true

            if (this.lastUserPosition) {
                this.isLocating = false
            } else {
                this.isLocating = true
                this.mapInstance.locate({ watch: true })
                return
            }

            const waypoints = this.fullBinAssets().map(
                ({ asset_details: { lat, lng } }) => [lat, lng]
            )

            if (!waypoints.length) {
                this.isLoading = false
                this.$notify({
                    title: this.$t('calculationErrorTitle'),
                    text: this.$t('calculationErrorText'),
                    group: 'center',
                })

                return
            }

            const { lat: userLat, lng: userLng } = this.lastUserPosition

            const coordinates = [[userLat, userLng], ...waypoints]
                .slice(0, 12)
                .map(([lat, lng]) => `${lng},${lat}`)
                .join(';')

            const { data } = await httpHelper.get(
                `https://api.mapbox.com/optimized-trips/v1/mapbox/driving/${coordinates}`,
                {
                    params: {
                        access_token: process.env.VUE_APP_MAPBOX_TOKEN,
                        geometries: 'geojson',
                        overview: 'full',
                    },
                }
            )

            if (data.trips.length) {
                this.setPositionTrackingEnabled(true)
                this.setNavigationRoute(
                    data.trips[0].geometry.coordinates.map(([a, b]) => [b, a])
                )

                this.mapInstance.fitBounds([[userLat, userLng], ...waypoints])
            }

            this.isLoading = false
        },
    },
}
</script>

<i18n>
{
    "en": {
        "calculate": "Calculate route",
        "calculationErrorTitle": "Calculation failed",
        "calculationErrorText": "No suitable waypoints found"

    },
    "de": {
        "calculate": "Route berechnen",
        "calculationErrorTitle": "Calculation failed",
        "calculationErrorText": "No suitable waypoints found"
    },
    "fr": {
        "calculate": "Calculer l'itinéraire",
        "calculationErrorTitle": "Calculer le champ",
        "calculationErrorText": "Aucun point de repère approprié n'a été trouvé"
    },
    "it": {
        "calculate": "Calcolare il percorso",
        "calculationErrorTitle": "Calculation failed",
        "calculationErrorText": "No suitable waypoints found"
    }
}
</i18n>
